import { PostLanguageShape } from 'Helper/BlogQuery';
import { HighlightColorTypeShape } from '_types/HighlightColor';
import { updateQueryStringParameter } from '_utils/QueryParameter';

export interface BasePaginatedParams {
    page: number;
    limit?: number;
}

export interface BasePaginatedParamsWithLanguage extends BasePaginatedParams {
    language: LanguageCode;
}

export interface PaginatedWithQueryParams extends BasePaginatedParams {
    q?: string;
    exclude_followed?: boolean;
}

export interface UsernameQueryParams {
    username: string;
}

export interface PostsQueryParams extends BasePaginatedParams {
    user_id: number;
    projects_only?: boolean;
}

export interface TagPostsQueryParams extends BasePaginatedParams {
    tag_name: string;
}

export interface FollowQueryParams extends PaginatedWithQueryParams {
    user_id: number;
    query?: string;
    count?: number;
}

export interface PostLikeListQueryParams extends BasePaginatedParams {
    post_id: number;
}

export interface CommentLikeListQueryParams extends BasePaginatedParams {
    comment_id: number;
}

export interface CommentsQueryParams extends BasePaginatedParams {
    post_id: number;
}

export interface YouTubeQueryParams {
    appLanguage: LanguageCode;
    nextPageToken?: string;
    maxResults?: number;
}

export interface BlogQueryParams {
    language: PostLanguageShape;
    limit?: number;
}
export type RemoveFollowerQueryParams = {
    user_id: number;
    follower_id: number;
    username: string | undefined;
};

export type StatsQueryParams = {
    year: number;
    user_id: number;
    month?: string;
};

export type HighlighterColorQueryParam = {
    user_id: number;
    type: HighlightColorTypeShape;
};

export type RecentlyViewedQueryParam = {
    skus?: string[];
};

export interface CategoryPaginatedParams
    extends BasePaginatedParamsWithLanguage {
    filter: string;
}

export interface DesignerRequestParams {
    storeId: string;
    code: string;
    language: LanguageCode;
}

export interface PatternRequestParams {
    sku: string;
    type: string;
    language: LanguageCode;
}

export interface SuitableForParams {
    attributeCode: string;
}
export type FollowerQueryParam = {
    user_id: number;
};

export type VideoCategoryQueryParam = {
    id: number;
};

type AnyDeclaredParams =
    | PostsQueryParams
    | UsernameQueryParams
    | PaginatedWithQueryParams
    | StatsQueryParams
    | HighlighterColorQueryParam
    | FollowerQueryParam
    | PatternPostsQueryParam
    | RecentlyViewedQueryParam
    | VideoCategoryQueryParam;

// adding into routing params
export const getRouteWithParams = (
    route: string,
    exploreApiConfig: AnyDeclaredParams,
): string => {
    // adding params to routes
    Object.keys(exploreApiConfig).forEach(function (key) {
        /**
         * We should not send any
         * undefined or blank value
         * to server
         */
        if (exploreApiConfig[key]) {
            route = updateQueryStringParameter(
                route,
                key,
                exploreApiConfig[key],
            );
        }
    });

    return route;
};

export type PatternPostsQueryParam = {
    sku: string;
    page_number?: number;
    limit?: number;
    expand_view?: boolean;
    q?: string;
};
