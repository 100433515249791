import { ReactNode } from 'react';
import Button from '_atoms/Button';

type VersionMessagesShape = {
    [key: string]: {
        en: ReactNode;
        no: ReactNode;
    };
};

const VersionUpgradeMessages: VersionMessagesShape = {
    '1.1.547': {
        en: (
            <ul>
                <li>
                    You will now find inspiration from our community in the
                    library pattern view 😍
                </li>
            </ul>
        ),

        no: (
            <ul>
                <li>
                    Du finner nå inspirasjon fra fellesskapet vårt på
                    oppskriftene i biblioteket 😍
                </li>
            </ul>
        ),
    },
    '1.1.545': {
        en: (
            <ul>
                <li>
                    Major upgrade of video tutorials 😍 You can find new videos
                    in “Stash & Tools” divided into many different categories!
                </li>
                <li>Fixed bugs with row counter and notes not always saving</li>
                <li>Other bug fixes</li>
            </ul>
        ),

        no: (
            <ul>
                <li>
                    Stor oppgradering av hjelpevideoer 😍 Du finner nye videoer
                    på “Stæsj & verktøy” inndelt i mange forskjellige
                    kategorier!
                </li>
                <li>
                    Utbedret feil med omgangsteller og notater som ikke lagres
                </li>
                <li>Øvrige bug fixes</li>
            </ul>
        ),
    },
    '1.1.530': {
        en: (
            <ul>
                <li>Improved search functionality in library 🎉</li>
                <li>Upgrade to Premium directly in the app 🔥</li>
                <li>
                    If you upgrade to Premium, you’ll receive a gift card for a
                    K&N tote bag 😍
                </li>
                <li>New and more relevant ads for free users</li>
                <li>Bug fixes</li>
            </ul>
        ),

        no: (
            <ul>
                <li>Forbedret søkefunksjon i biblioteket 🎉</li>
                <li>Oppgrader til Premium direkte i appen 🔥</li>
                <li>
                    Hvis du oppgraderer til Premium får du gavekort på en K&N
                    totebag 😍
                </li>
                <li>Nye og mer relevante reklamer for gratisbrukeres</li>
                <li>Bug fixes</li>
            </ul>
        ),
    },
    '1.1.508': {
        en: (
            <ul>
                <li>
                    You can now add patterns and designers to your favorites in
                    the library ♥️
                </li>
                <li>Bug fixes</li>
            </ul>
        ),

        no: (
            <ul>
                <li>
                    Nå kan du favorisere oppskrifter og designere i biblioteket
                    ♥️
                </li>
                <li>Bug fixes</li>
            </ul>
        ),
    },
    '1.1.489': {
        en: (
            <ul>
                <li>
                    Needle size and gauge are automatically added to projects
                    started from the library
                </li>
                <li>
                    Needle size and gauge will now be converted to imperial in
                    library for those that have this setting
                </li>
                <li>
                    Trending library patterns are now displayed on the home page
                    as well
                </li>
                <li>Bug fixes</li>
            </ul>
        ),

        no: (
            <ul>
                <li>
                    Pinnestørrelse og strikkefasthet legges automatisk til i
                    prosjekter startet fra biblioteket
                </li>
                <li>
                    Pinnestørrelse og strikkefasthet blir nå konvertert til
                    imperial for de som har valgt denne innstillingen
                </li>
                <li>
                    Oppskrifter som trender i biblioteket vises nå også på
                    hjemsiden
                </li>
                <li>Bug fixes</li>
            </ul>
        ),
    },
    '1.1.469': {
        en: (
            <ul>
                <li>You can now upload new patterns directly in a project</li>
                <li>
                    It’s possible to view a list of your whole pattern
                    collection 😍
                </li>
                <li>
                    The streak shows how many times you’ve completed projects
                    with the pattern 🔥
                </li>
                <li>
                    We’ve made it easier to upload new patterns, and now you can
                    also add links
                </li>
                <li>
                    Projects kan be started and planned from your patterns 🧶
                </li>
            </ul>
        ),

        no: (
            <ul>
                <li>
                    Du kan nå laste opp nye oppskrifter direkte i et prosjekt
                </li>
                <li>Det er mulig å se alle oppskriftene dine samlet 😍</li>
                <li>
                    Streaken viser hvor mange ganger du har fullført prosjekt
                    med oppskriften 🔥
                </li>
                <li>
                    Vi har gjort det lettere å laste opp nye oppskrifter, og nå
                    kan også linker legges til
                </li>
                <li>
                    Prosjekter kan startes og planlegges fra oppskriftene dine
                    🧶
                </li>
            </ul>
        ),
    },
    '1.1.457': {
        en: (
            <ul>
                <li>
                    The pattern library with hundreds of patterns are now
                    available in the new premium subscription
                </li>
                <li>
                    Explore all patterns in the free version, and upgrade to
                    premium (via browser) to use them
                </li>
                <li>
                    For more information, read the latest newsletter sent to
                    your email
                </li>
                <li>Your personal patterns are moved to Stash & tools</li>
            </ul>
        ),

        no: (
            <ul>
                <li>
                    Oppskriftsbiblioteket med hundrevis av oppskrifter er nå
                    tilgjengelig i det nye premiumabonnementet
                </li>
                <li>
                    Du kan utforske oppskriftene i gratisversjonen og oppgradere
                    til premium (via nettleser) for å bruke dem
                </li>
                <li>
                    For mer informasjon, les det siste nyhetsbrevet sendt til
                    din epost
                </li>
                <li>
                    Dine personlige oppskrifter er flyttet til Stæsj & verktøy
                </li>
            </ul>
        ),
    },
    '1.1.413': {
        en: (
            <ul>
                <li>
                    You can now share a screenshot of your project overview to
                    Instagram or other platforms by clicking the “Share” button
                    😌
                </li>
                <li>Bug fixes</li>
            </ul>
        ),

        no: (
            <ul>
                <li>
                    You can now share a screenshot of your project overview to
                    Instagram or other platforms by clicking the “Share” button
                    😌
                </li>
                <li>Bug fixes</li>
            </ul>
        ),
    },
    '1.1.407': {
        en: (
            <ul>
                <li>
                    We’ve gathered all your stash and tools in one place! 😍
                    Yarn stash, needle stash and all tools can now be found in
                    “Stash & tools” in the bottom menu
                </li>
                <li>
                    You’ll find settings and all information about your account
                    by clicking the avatar icon at the top right corner on
                    “Home”
                </li>
                <li>
                    You can now share a screenshot of your completed projects
                    directly to instagram etc. by clicking the share button on
                    “Completed projects” 🤗
                </li>
                <li>Bug fixes</li>
            </ul>
        ),

        no: (
            <ul>
                <li>
                    Vi har samlet alt stæsjet ditt og verktøy en plass! 😍
                    Garnlager, pinnelager og alle verktøy finnes nå i “Stæsj &
                    verktøy” i bunnmenyen
                </li>
                <li>
                    Du finner innstillinger og all informasjon om din konto ved
                    å trykke på avatarikonet oppe til høyre på “Hjem”
                </li>
                <li>
                    Du kan nå dele et skjermbilde av dine fullførte prosjekter
                    direkte til Instagram o.l. ved å trykke på deleknappen på
                    “Fullførte prosjekter” 🤗
                </li>
                <li>Bug fixes</li>
            </ul>
        ),
    },
    '1.1.392': {
        en: (
            <ul>
                <li>You can view your yarn consumption by month in Overview</li>
                <li>
                    In completed projects, you can search for yarn, gauge,
                    pattern and designer
                </li>
                <li>Ruler improvements</li>
                <li>Bug fixes</li>
            </ul>
        ),

        no: (
            <ul>
                <li>Du kan se garnforbruket ditt per måned i Oversikt</li>
                <li>
                    I fullførte prosjekter kan du søke på garn, strikkefasthet,
                    oppskrift og designer
                </li>
                <li>Forbedringer på linjal</li>
                <li>Bug fixes</li>
            </ul>
        ),
    },
    '1.1.375': {
        en: (
            <ul>
                <li>
                    You can now view completed projects for an entire year or a
                    specific month 😍
                </li>
                <li>We have made it possible to duplicate projects ☺️</li>
                <li>Improved ruler in pattern view</li>
                <li>Added more gauge options</li>
                <li>More menus have become slideups</li>
                <li>Bug fixes</li>
            </ul>
        ),

        no: (
            <ul>
                <li>
                    Du kan nå se fullførte prosjekter for et helt år eller en
                    spesifikk måned 😍
                </li>
                <li>Vi har gjort det mulig å duplisere prosjekter ☺️</li>
                <li>Forbedret linjal i oppskriftsvisning</li>
                <li>Lagt til flere valg på strikkefasthet</li>
                <li>Flere menyer har blitt til slideups</li>
                <li>Bug fixes</li>
            </ul>
        ),
    },
    '1.1.359': {
        en: (
            <ul>
                <li>
                    We’ve increased the image size on the project page and on
                    project cards
                </li>
                <li>
                    Next time you finish a project you will get a nice surprise
                    🤗
                </li>
                <li>
                    Hopefully we fixed some of the issues with row counters 🤞🏻
                </li>
                <li>Bug fixes</li>
            </ul>
        ),

        no: (
            <ul>
                <li>
                    Vi har økt bildestørrelsen på prosjektsiden og
                    prosjektkortene
                </li>
                <li>
                    Neste gang du fullfører et prosjekt vil du få en hyggelig
                    overraskelse 🤗
                </li>
                <li>
                    Forhåpentligvis har vi fikset noen av problemene med
                    omgangstellere 🤞🏻
                </li>
                <li>Bug fixes</li>
            </ul>
        ),
    },
    '1.1.333': {
        en: (
            <ul>
                <li>
                    We’ve just launched a bundle of tools to make your life so
                    much easier! Go to More {'>'} Tools to explore them!
                </li>
                <li>
                    Improvements in pattern view: PDF links are now clickable,
                    and the app remembers your last viewed pattern page before
                    exiting, ensuring you pick up right where you left off.
                </li>
                <li>Improvements in tablet view</li>
                <li>Minor bug fixes</li>
            </ul>
        ),

        no: (
            <ul>
                <li>
                    Vi har nettopp lansert en samling av verktøy som vil gjøre
                    livet ditt enklere! Gå til Mer {'>'} Verktøy for å utforske
                    dem
                </li>
                <li>
                    Forbedringer i oppskriftsvisning: PDF-linker er nå
                    klikkbare, og appen husker hvor i oppskriften du var når du
                    gikk ut av oppskriften forrige gang!
                </li>
                <li>Forbedringer på nettbrett</li>
                <li>Bug fixes</li>
            </ul>
        ),
    },
    '1.1.296': {
        en: (
            <>
                <h3>Bugfix</h3>
                <ul>
                    <li>
                        Now highlighting and other tools in the pattern should
                        work as expected
                    </li>
                </ul>
            </>
        ),
        no: (
            <>
                <h3>Bugfix</h3>
                <ul>
                    <li>
                        Nå burde markering og andre verktøy i oppskriften virke
                        som forventet
                    </li>
                </ul>
            </>
        ),
    },
    '1.1.294': {
        en: (
            <>
                <p>New release 🥳</p>
                <ul>
                    <li>
                        Big update in the project pattern view where we’ve
                        collected all tools in one menu:
                        <ul>
                            <li>
                                You have the option to keep the screen on while
                                looking at your pattern (only on app downloaded
                                from App Store / Google Play)
                            </li>
                            <li>Added pen tool and ruler</li>
                            <li>
                                You may add you own colors and select thickness
                                for your pen and marker
                            </li>
                        </ul>
                    </li>
                    <li>
                        Some neat tips will pop up helping you get the most out
                        of the app
                    </li>
                    <li>We’ve improved loading time in Community</li>
                    <li>Bug fixes</li>
                </ul>
            </>
        ),
        no: (
            <>
                <p>Ny oppdatering 🥳</p>
                <ul>
                    <li>
                        Stor oppdatering i oppskriftsvisningen på prosjekter der
                        vi har samlet alle verktøy i en meny:
                        <ul>
                            <li>
                                Du har valget om å holde skjermen på mens du ser
                                på oppskriften (kun på app lastet ned fra App
                                Store / Google Play)
                            </li>
                            <li>Lagt til penn og linjal</li>
                            <li>
                                Du kan legge til dine egne farger og velge
                                tykkelse på pennen og tusjen din
                            </li>
                        </ul>
                    </li>
                    <li>
                        Tips vil dukke opp som hjelper deg til å få mest mulig
                        ut av appen
                    </li>
                    <li>Vi har forbedret lastetiden i Fellesskapet</li>
                    <li>Bug fixes</li>
                </ul>
            </>
        ),
    },
    '1.1.276': {
        en: (
            <>
                <p>New update 🥳</p>
                <ul>
                    <li>
                        We’ve done some design updates in the yarn stash and
                        added a separate area for notetaking
                    </li>
                    <li>
                        A new design update for profile and settings- now you
                        can easily change your subscription or change the email
                        you’re logged in with
                    </li>
                    <li>We’ve added new icons you can use in the journal</li>
                    <li>
                        In your projects you can now choose to add multiple
                        knitting needles and you can choose not to add gauge.
                    </li>
                </ul>
            </>
        ),
        no: (
            <>
                <p>Ny oppdatering 🥳</p>
                <ul>
                    <li>
                        Vi har gjort en designoppdatering i garnlager og lagt på
                        et eget felt til notater
                    </li>
                    <li>
                        Nytt design på Profil og innstillinger - nå er det
                        enkelt å endre abonnement eller bytte email du er
                        innlogget med.
                    </li>
                    <li>Nye ikoner du kan bruke i Journal</li>
                    <li>
                        I prosjektene dine kan du nå huke av flere pinner og
                        velge å ikke legge inn strikkefasthet
                    </li>
                </ul>
            </>
        ),
    },
    '1.1.262': {
        en: (
            <>
                <p>New update 🥳 Here you will find:</p>
                <ul>
                    <li>
                        Easier to upgrade your subscription to premium or
                        contributor (go to More{'>'}Profile and Settings
                        {'>'}Membership)
                    </li>
                    <li>A number of small adjustments and bug fixes</li>
                </ul>
                <p>Wishing you all a happy week!</p>
            </>
        ),
        no: (
            <>
                <p>Ny oppdatering 🥳 Her finner du:</p>
                <ul>
                    <li>
                        Enklere å oppgradere abonnementet ditt til premium eller
                        bidragsyter (gå inn på Mer{'>'}Profil og Innstillinger
                        {'>'}Medlemskap)
                    </li>
                    <li>En del små justeringer og bug fixes</li>
                </ul>
                <p>Ønsker dere alle en god uke!</p>
            </>
        ),
    },
    '1.1.261': {
        en: (
            <>
                <p>New update 🥳 Here you will find:</p>
                <ul>
                    <li>
                        Easier to upgrade your subscription to premium or
                        contributor (go to More{'>'}Profile and Settings
                        {'>'}Membership)
                    </li>
                    <li>A number of small adjustments and bug fixes</li>
                </ul>
                <p>Wishing you all a happy week!</p>
            </>
        ),
        no: (
            <>
                <p>Ny oppdatering 🥳 Her finner du:</p>
                <ul>
                    <li>
                        Enklere å oppgradere abonnementet ditt til premium eller
                        bidragsyter (gå inn på Mer{'>'}Profil og Innstillinger
                        {'>'}Medlemskap)
                    </li>
                    <li>En del små justeringer og bug fixes</li>
                </ul>
                <p>Ønsker dere alle en god uke!</p>
            </>
        ),
    },

    '1.1.231': {
        en: (
            <>
                <p>
                    We are now launching the previously mentioned changes in our
                    subscription models, and here they are:
                </p>
                <ul>
                    <li>
                        <b>Free</b>: access to everything, with ads.
                    </li>
                    <li>
                        <b>Premium</b>: Ad free experience, and more benefits
                        coming up.
                        <ul>
                            <li>2.99 USD/ month or 29.99 USD/ year.</li>
                        </ul>
                    </li>
                    <li>
                        <b>Contributer</b>: For those who really love Knit&Note
                        and want to contribute to our app. Who knows, maybe
                        you’ll be surprised for your generosity.
                        <ul>
                            <li>39.99 USD/ Year</li>
                        </ul>
                    </li>
                </ul>
                <p>
                    To kick start the year and our new subscription, models we
                    will draw a lucky winner amongst the active premium and
                    contributor subscribers each month. The prize? A surprise
                    each month throughout the year.
                </p>
                <p>
                    Upgrade your subscription{' '}
                    <a href="/subscription-management">
                        <b>here</b>
                    </a>
                </p>
            </>
        ),
        no: (
            <>
                <p>
                    Endringer i abonnementsmodellen! Fra og med i dag vil
                    abonnementene være:
                </p>
                <ul>
                    <li>
                        <b>Gratis</b>: tilgang til alle funksjoner, med reklame
                    </li>
                    <li>
                        <b>Premium</b>: Reklamefri opplevelse, og flere fordeler
                        vil komme
                        <ul>
                            <li>2.99USD/ måneden eller 29.99 USD/ året</li>
                        </ul>
                    </li>
                    <li>
                        <b>Bidragsyter</b>: For deg som virkelig elsker
                        Knit&Note og vil bidra til utviklingen. Og hvem vet,
                        kanskje det drypper noe tilbake på deg?
                        <ul>
                            <li>39.99 USD/ året</li>
                        </ul>
                    </li>
                </ul>
                <p>
                    For å kickstarte året og ny abonnementsmodell vil vi trekke
                    en heldig vinner blant aktive Premium- og bidargsytere hver
                    måned ut året! Premien? En overraskelse som vil annonseres
                    når vinner trekkes.
                </p>
                <p>
                    Oppgrader til premium eller bidragsyter{' '}
                    <a href="/subscription-management">
                        <b>her</b>
                    </a>
                </p>
            </>
        ),
    },
    '1.1.223': {
        en: (
            <>
                <p>New update 🥳</p>
                <ul>
                    <li>
                        You can now use hashtags when posting a project in the
                        community! These are searchable in «Explore»
                    </li>
                    <li>
                        You can also tag other users in both comments and
                        captions.
                    </li>
                    <li>
                        We have improved the pen marker and row counter in the
                        projects as some have experienced that these have not
                        been saved previously.
                    </li>
                    <li>Other small bugfixes</li>
                </ul>
                <p>
                    We are grateful for all the feedback we receive and
                    encourage you to send an email to help@knitandnote.com if
                    you experience errors or have suggestions for improvements
                    ☺️
                </p>
            </>
        ),
        no: (
            <>
                <p>Ny oppdatering 🥳</p>
                <ul>
                    <li>
                        Du kan nå bruke hashtags når du legger ut et prosjekt i
                        fellesskapet! Disse er søkbare i «Utforsk»
                    </li>
                    <li>
                        Du kan også tagge andre brukere i både kommentarer og
                        caption.
                    </li>
                    <li>
                        Vi har forbedret markeringstusj og omgangsteller i
                        prosjektene da noen har opplevd at disse ikke har blitt
                        lagret tidligere.
                    </li>
                    <li>Fikset en feil i «Min oversikt»</li>
                </ul>
                <p>
                    Vi er takknemlige for alle tilbakemeldinger vi får og
                    oppfordrer deg til å sende mail til help@knitandnote.com
                    dersom du opplever feil eller har forslag til forbedringer
                    ☺️
                </p>
            </>
        ),
    },
    '1.1.213': {
        en: (
            <>
                <p>Update alert 🤩🥳</p>
                <ul>
                    <li>You can now share projects without a pattern</li>
                    <li>Improved loading time</li>
                    <li>Improved scrolling experience</li>
                    <li>
                        Suggestions for new accounts to follow in between
                        projects in your feed
                    </li>
                    <li>Other bug fixes</li>
                </ul>
                <p>Wish you a great week 🧶🎉</p>
            </>
        ),
        no: (
            <>
                <p>Ny oppdatering 🤩🥳</p>
                <ul>
                    <li>Du kan nå dele prosjekter også uten oppskrift</li>
                    <li>Forbedret lastetid</li>
                    <li>Forbedret scrolling</li>
                    <li>
                        Forslag til nye kontoer å følge mellom prosjekter i
                        feeden din
                    </li>
                    <li>Annen bugfix</li>
                </ul>
                <p>Ønsker deg en flott uke 🧶🎉</p>
            </>
        ),
    },
    '1.1.207': {
        en: (
            <>
                <p>
                    Almost two weeks ago we launched the Community in knit&note
                    and you can now share your projects with others and get
                    inspired by others projects 🤩
                </p>
                <p>
                    Today we’ve released a new update to improve some features
                    and fix a couple of bugs. Here are some of them:
                </p>
                <ul>
                    <li>
                        Everyone should now be able to write comma i needle
                        stash (Ex: needle size 2.5mm etc)
                    </li>
                    <li>
                        Scroll menu for gauge and needles in projects are
                        updated
                    </li>
                    <li>
                        Fixed issue so that it’s now possible to create custom
                        journal notes again
                    </li>
                    <li>
                        Improvements in Community:
                        <ul>
                            <li>
                                fixed issue where next page wasn’t loading in
                                followers/following
                            </li>
                            <li>improvements for older iPhones</li>
                            <li>
                                the first project picture is now default
                                selected when sharing a project
                            </li>
                            <li>improved scrolling experience</li>
                        </ul>
                    </li>
                </ul>
                <p>
                    Hope you enjoy the Community ☺️ We love seeing that you use
                    it and share your projects with others ❤️
                </p>
            </>
        ),
        no: (
            <>
                <p>
                    For snart to uker siden lanserte vi fellesskapet i
                    knit&note, og du kan nå dele prosjektene dine med andre og
                    bli inspirert av andres prosjekter 🤩
                </p>
                <p>
                    I dag har vi pushet en ny oppdatering for å forbedre noen
                    funksjoner og fikse et par feil. Her er noen av dem:
                </p>
                <ul>
                    <li>
                        Alle skal nå kunne skrive komma i pinnelageret (eks.
                        pinnestørrelse 2,5 mm osv.)
                    </li>
                    <li>
                        Scrollmenyen for strikkefasthet og pinner i prosjekter
                        er oppdatert
                    </li>
                    <li>
                        Fikset bug sånn at det nå er mulig å lage egendefinerte
                        journalnotater igjen
                    </li>
                    <li>
                        Forbedringer i fellesskapet:
                        <ul>
                            <li>
                                fikset et problem der neste side ikke ble lastet
                                inn i følgere/følger
                            </li>
                            <li>forbedringer for eldre iPhones</li>
                            <li>
                                det første prosjektbildet er nå valgt som
                                standard når du deler et prosjekt
                            </li>
                            <li>forbedret scrolleopplevelse</li>
                        </ul>
                    </li>
                </ul>
                <p>
                    Håper du liker fellesskapet ☺️ Vi elsker å se at du bruker
                    det og deler prosjektene dine med andre ❤️
                </p>
            </>
        ),
    },
    '1.1.148': {
        en: (
            <ul>
                <li>
                    If you move a project from completed to active, the start
                    date will now remain unchanged
                </li>
                <li>The project images have become slightly larger </li>
                <li>Small bug fixes and improvements</li>
            </ul>
        ),
        no: (
            <ul>
                <li>
                    Om du flytter et prosjekt fra fullført til aktivt, vil
                    startdatoen nå forbli uendret
                </li>
                <li>Prosjektbildene har blitt litt større</li>
                <li>Små bugfixes og forbedringer</li>
            </ul>
        ),
    },
    '1.1.147': {
        en: (
            <ul>
                <li>
                    If you move a project from completed to active, the start
                    date will now remain unchanged
                </li>
                <li>The project images have become slightly larger </li>
                <li>Small bug fixes and improvements</li>
            </ul>
        ),
        no: (
            <ul>
                <li>
                    Om du flytter et prosjekt fra fullført til aktivt, vil
                    startdatoen nå forbli uendret
                </li>
                <li>Prosjektbildene har blitt litt større</li>
                <li>Små bugfixes og forbedringer</li>
            </ul>
        ),
    },
    '1.1.132': {
        en: (
            <>
                <p>Wow! We have really been looking forward to this update!!</p>
                <p>
                    Our goal is to make it easier and more inspiring to use K&N.
                    To achieve this we have redesigned almost the entire app!
                    You can also find a few new features like:
                </p>

                <ul>
                    <li>
                        Inspiration from the blog directly on the home screen
                    </li>
                    <li>Technique videos</li>
                    <li>
                        Badge rewards for your knitting accomplishments 🥇🧶
                    </li>
                </ul>
                <p>
                    Feel free to explore - we hope you like it! We would be
                    happy if you share the news. Feel free to tag us
                    @knitandnote on Instagram ☺️
                </p>
            </>
        ),
        no: (
            <>
                <p>Wow! Denne oppdateringen har vi gledet oss til!!</p>
                <p>
                    For at det skal bli enklere og mer inspirerende å bruke k&n
                    har vi nå redeseignet (omtrent) hele appen. I tillegg kan du
                    finne en del nye funksjoner som:
                </p>

                <ul>
                    <li>Inspirasjon fra bloggen direkte på hjemskjermen.</li>
                    <li>Teknikkvideoer (snart kommer norske også)</li>
                    <li>
                        Hvis du er heldig kan du gjerne sikre deg en medalje
                        eller kanskje fler? 🙊🧶
                    </li>
                </ul>
                <p>
                    Utforsk gjerne - håper du blir fornøyd! Vi blir då glad om
                    dere deler. Tag oss gjerne med @knitandnote på Instagram ☺️
                </p>
            </>
        ),
    },
    '1.1.110': {
        en: (
            <ul>
                <li>
                    now you can change the name and icon of all pattern
                    categories
                </li>
                <li>
                    when deleting a category, you can now choose to delete all
                    the patterns under it as well
                </li>
                <li>fixed an issue with sorting on pattern as images</li>
                <li>
                    fixed an issue where starting date on projects could not be
                    edited in some cases
                </li>
                <li>potentially fixed an issue with missing pdf content</li>
            </ul>
        ),
        no: (
            <ul>
                <li>nå kan du endre navn og ikon I alle oppskriftkategorier</li>
                <li>
                    når du sletter en kategori, kan du nå velge å slette alle
                    oppskrift i kategorien også
                </li>
                <li>fikset et problem med sortering på oppskrift som bilder</li>
                <li>
                    fikset et problem der startdatoen på prosjekter ikke kunne
                    redigeres i noen tilfeller
                </li>
                <li>potensielt fikset et problem med manglende pdf-innhold</li>
            </ul>
        ),
    },
    '1.1.91': {
        en: (
            <>
                <p>
                    Now you have the option to use different measurement
                    systems.
                </p>
                <ul>
                    <li>
                        you can choose your preferred measurement system from
                        your Account page, which will be used for totals and
                        stats
                    </li>
                    <li>
                        you can choose to convert your measurements from one
                        system to another
                    </li>
                    <li>
                        you can select different measurement units when creating
                        or updating your yarn stash and project yarns
                    </li>
                </ul>
            </>
        ),
        no: (
            <>
                <p>
                    Nå har du muligheten til å bruke forskjellige målesystemer.
                </p>
                <ul>
                    <li>
                        du kan velge ditt foretrukne målesystem fra kontosiden
                        din, som vil bli brukt for totaler og statistikk
                    </li>
                    <li>
                        du kan velge å konvertere målingene dine fra ett system
                        til et annet
                    </li>
                    <li>
                        du kan velge forskjellige måleenheter når du oppretter
                        eller oppdaterer garnlageret og prosjektgarn
                    </li>
                </ul>
            </>
        ),
    },
    '1.1.76': {
        en: (
            <p>
                Good news! You can now upload pictures as patterns. Simply just
                click the “Upload as pictures” button when uploading a new
                pattern. Then you can select multiple pictures and order them as
                you wish.
            </p>
        ),
        no: (
            <p>
                Gode nyheter! Nå kan du laste opp bilder som oppskrifter. Det
                gjør du enkelt ved å klikke på «Last opp som bilder» når du skal
                laste opp en oppskrift. Deretter velger du bildene du vil ha med
                i oppskriften din og sorterer dem som du ønsker
            </p>
        ),
    },
    '1.1.70': {
        en: (
            <ul>
                <li>
                    Fixed an issue with some users being logged out while
                    highlighting on patterns
                </li>
            </ul>
        ),
        no: (
            <ul>
                <li>
                    Fikset et problem med at noen brukere ble logget ut mens de
                    brukte markeringstusjen
                </li>
            </ul>
        ),
    },
    '1.1.66': {
        en: (
            <ul>
                <li>
                    Fixed an issue with uploading patterns from google drive
                </li>
            </ul>
        ),
        no: (
            <ul>
                <li>
                    Rettet et problem med opplasting av oppskrifter fra Google
                    Drive
                </li>
            </ul>
        ),
    },
    '1.1.65': {
        en: (
            <ul>
                <li>Fixed an issue with changing pattern name</li>
            </ul>
        ),
        no: (
            <ul>
                <li>Rettet et problem med å endre oppskriftnavn</li>
            </ul>
        ),
    },
    '1.1.61': {
        en: (
            <ul>
                <li>
                    Lots of fixes to make sure the app is working as it should
                </li>
                <li>
                    Photos of yarn in projects remain even if it is removed from
                    Yarn stash
                </li>
                <li>Improvements when you are adding yarn to projects</li>
                <li>Optimizing the PDF pattern viewer</li>
            </ul>
        ),
        no: (
            <ul>
                <li>Småfikser for å sikre at appen fungerer som den skal</li>
                <li>
                    Bilder av garn i et prosjekt blir værende, selv om du
                    sletter garnet fra garnlageret
                </li>

                <li>Forbedringer når garn legges til i prosjekter</li>
                <li>Optimalisering av PDF-oppskriftsvisninger</li>
            </ul>
        ),
    },
    '1.1.58': {
        en: (
            <ul>
                <li>
                    Improvements to the pattern search: now has an improved look
                    and usability, and the search now includes all patterns
                    inside subcategories
                </li>
                <li>
                    Updates to the pattern category and overall pattern view
                    style
                </li>
                <li>
                    Prevention of errors when calculating your yarn statistics
                </li>
                <li>Improved pattern highlighting</li>
                <li>More options added for updating your project status</li>
                <li>
                    Gram per skein and length per skein are now required fields
                </li>
            </ul>
        ),
        no: (
            <ul>
                <li>Forbedringer i søkefunksjonen i «Mine oppskifter»</li>
                <li>Mer korrekt utregning av garnbruk</li>
                <li>Forbedret markeringsfunksjon i PDFene</li>
                <li>
                    Mulighet til å endre prosjektstatus fra for eksempel
                    «Fullført» til «Pågående»
                </li>
                <li>
                    Gram per nøste og lengde per nøste er nå obligatoriske felt.
                    Dette for å unngå feil i garnbruk statistikken
                </li>
            </ul>
        ),
    },
    '1.1.46': {
        en: (
            <ul>
                <li>
                    Drag and drop functionality in “Knitting plans”. This means
                    you can now change order of your knitting plans by just
                    simply long press on the plan you would like to move:)
                </li>
                <li>Decreased loading time for your PDFs</li>
            </ul>
        ),
        no: (
            <ul>
                <li>
                    Mulighet til å endre rekkefølge på strikkeplanene dine! Bare
                    hold inne den du vil flytte, og dra den dit du vil ha den:)
                </li>
                <li>Kortere lastetid på pdfene dine</li>
            </ul>
        ),
    },
    '1.1.44': {
        en: (
            <ul>
                <li>Increased quality when you zoom in on your patterns</li>
                <li>
                    Fixed errors when viewing the months in the journal calendar
                </li>
                <li>Decrease in the time it takes for your pattern to save</li>
                <li>Improved pattern thumbnail</li>
                <li>
                    Yarn usage stats are now calculated without decimals to
                    prevent errors
                </li>
            </ul>
        ),

        no: (
            <ul>
                <li>
                    Forbedret kvalitet når du zoomer inn i oppskriftene dine
                </li>
                <li>Bugfix i kalenderen</li>
                <li>Opplastning av oppskrifter går fortere</li>
                <li>
                    Garnlager statistikk blir lagret uten desimaler for å unngå
                    feil
                </li>
            </ul>
        ),
    },
    '1.1.40': {
        en: (
            <ul>
                <li>
                    Improved pdf viewer for a better pattern viewing experience
                    and more accurate highlighting in your patterns.
                </li>
                <li>
                    Pattern count for each category now includes patterns that
                    are organized in subcategories.
                </li>
                <li>
                    New method for calculating the amount of meters used in your
                    knitting statistics. This will give a more accurate length.
                </li>
                <li>
                    Download option has been added in the pattern library so you
                    can download your patterns directly from the app.
                </li>
            </ul>
        ),
        no: (
            <ul>
                <li>
                    Forbedret visning av pdf-filer og markering i oppskriftene
                </li>
                <li>
                    Antallet oppskrifter vist under hovedkategoriene inkluderer
                    nå også alle oppskriftene i underkategoriene.
                </li>
                <li>
                    Ny metode for å regne ut totalt brukte meter med garn, som
                    gjør at dette blir mer nøyaktig.
                </li>
                <li>
                    Du kan nå laste ned oppskriftene dine fra appen slik at de
                    kan printes osv:)
                </li>
            </ul>
        ),
    },
    '1.1.34': {
        en: (
            <ul>
                <li>
                    We have added the possibility to add subcategories in every
                    pattern folder, and we have renewed the category icons :)
                </li>
                <li>
                    We have also fixed a bug in the row counter, and done some
                    optimization for pattern loading.
                </li>
            </ul>
        ),

        no: (
            <ul>
                <li>
                    Vi har nå lagt til muligheten for å opprette underkategorier
                    til steikkeoppskriftene, og oppdatert ikonene som brukes i
                    oppskriftsbiblioteket :)
                </li>
                <li>
                    I tillegg har vi fikset en bug i omgangstelleren, og gjort
                    litt optimalisering på lasting av oppskrifter!
                </li>
            </ul>
        ),
    },
    '1.1.23': {
        en: (
            <p>
                A small update has been released with some updates and
                optimizations of the PDF-viewer. This includes a fix for
                improved navigation while zoomed in, and some more stuff
                happening on the backside to make sure you have a smooth
                experience.
            </p>
        ),
        no: (
            <p>
                En liten oppdatering knyttet til PDF-ene. Vi har gjort noen
                justeringer for å optimalisere bruken, og inkludert en fiks for
                å forbedre navigering når en har zoomet inn i oppskriften.
            </p>
        ),
    },
    '1.1.17': {
        en: (
            <>
                <ul>
                    <li>
                        Don&apos;t need all the default categories? You can now
                        delete the ones you don&apos;t use. Simply go to the
                        category you would like to delete and press the three
                        dots you now find in the upper right corner, and select
                        &quot;delete&quot;.
                    </li>
                    <li>
                        We have also added some fixes on the main photo of your
                        yarn stash to improve the user expereience
                    </li>
                </ul>
            </>
        ),
        no: (
            <>
                <ul>
                    <li>
                        Bruker du ikke alle kategoriene som er standard for
                        oppskriftene? Du kan nå slette standard kategorier du
                        ikke bruker. På hver kategori finner du tre prikker
                        øverst hvor du kan velge å slette kategorien du er i.
                    </li>
                    <li>
                        Vi har også gjort noen småfiks i garnlageret knyttet til
                        hovedbildet av garnet for å forbedre brukeropplevelsen
                    </li>
                </ul>
            </>
        ),
    },
    '1.1.1': {
        en: (
            <>
                <ul>
                    <li>
                        Projects.Major update to project components to improve
                        functionality in future releases
                    </li>
                    <li>
                        Improved invite friend functions including a copy link
                        button
                    </li>
                    <li>
                        Yarn stash. Added functionality allowing users to choose
                        yarn type-picture from colors and upload a new picture
                    </li>
                    <li>Pattern.Improved pattern view, bugfix</li>
                    <li>
                        Performance. Lazy loading improved to reduce app load
                        time
                    </li>
                    <li>
                        UX. IOS status bar background adjusted to improve user
                        experience
                    </li>
                    <li>
                        UX. All user forms are updated to improve the user
                        experience
                    </li>
                </ul>
            </>
        ),
        no: (
            <>
                <ul>
                    <li>
                        Prosjekt. Oppdatert prosjekt komponenter med ny versjon.
                        Dette for å ta høyde for de nye featurene som kommer i
                        senere versjoner samt bugfixes.
                    </li>
                    <li>
                        Forbedret inviter venn funksjoner og ny kopier link
                        knapp lagt til
                    </li>
                    <li>
                        Garnlager. Oppdatert funksjonalitet for å la bruker
                        velge bilde av garntype samt opplastning av nye bilder.
                    </li>
                    <li>
                        Oppskrifter. Oppdatert oppskrift fremvisning , bugfix.
                    </li>
                    <li>
                        Lastetid. Lazy loading optimalisert for å forbedre
                        lastetid.
                    </li>
                    <li>
                        UX. IOS status bar bakgrunn justert for å forbedre
                        bruker opplevelse
                    </li>
                    <li>
                        UX. Alle skjema og input felt er er oppdatert for å
                        forbedre bruker opplevelsen
                    </li>
                </ul>
            </>
        ),
    },
    '1.0.286': {
        en: (
            <>
                <ul>
                    <li>
                        Active projects are now sorted by last accessed at date
                    </li>
                    <li>
                        Now you can see a default yarn picture in the yarn stash
                        list
                    </li>
                    <li>
                        You can now remove the pattern from a project my using
                        the options button
                    </li>
                    <li>
                        Now the Add yarn button is visible even when scrolling
                    </li>
                </ul>
            </>
        ),
        no: (
            <>
                <ul>
                    <li>Aktive prosjekter sorteres nå etter sist åpnet</li>
                    <li>
                        Nå får du opp et bilde i oversiktene over alle
                        garnsortene dine i garnlageret (gjelder for de som
                        allerede har lagt inn bilder av garnet)
                    </li>
                    <li>
                        Du kan nå slette oppskriften fra prosjektet ved å bruke
                        menyen i høyre hjørne
                    </li>
                    <li>
                        «Legg til nytt garn» knappen i garnlageret er nå synlig
                        selv om du scroller
                    </li>
                </ul>
            </>
        ),
    },
    '1.0.278': {
        en: (
            <>
                <p>
                    We have added a fix so that your yarn can return to your
                    yarn stash also if you delete a planned or active project.
                    This goes for projects where the yarn is added from the yarn
                    stash :)
                </p>
            </>
        ),
        no: (
            <>
                <p>
                    Vi har lagt til en fiks slik at du kan returnere garn til
                    garnlageret også når du sletter et planlagt eller påbegynt
                    prosjekt. Det gjelder prosjekter hvor garn i prosjektet er
                    hentet fra garnlager :)
                </p>
            </>
        ),
    },
    '1.0.273': {
        en: (
            <>
                <ul>
                    <li>
                        We have added a download button in the menu you access
                        on your home screen, if you are logged in through your
                        browser.
                    </li>
                </ul>
                <p>
                    <b>Tip:</b> You also find &quot;invite friends&quot; in the
                    same menu, both in app-version and when logged in thorugh
                    your browser. Go there to copy or share your personal link
                    and see how many friends you have invited.
                </p>
                <p>
                    For every friend you invite you get an extra month of free
                    premium, and if more than three of your friends has
                    registered within 14.02.22 you are in the draw of 10 gift
                    cards of 115 USD in the yarn shop of your choice.
                </p>
            </>
        ),
        no: (
            <>
                <ul>
                    <li>
                        Vi har lagt til en knapp for å laste ned appen til
                        telefonen når du er innlogget i nettleseren. Du finner
                        denne i menyen oppe til høyre på hjemskjermen.
                    </li>
                </ul>
                <p>
                    <b>Dagens tips:</b> Du finner også &quot;inviter
                    venner&quot; i samme meny, både i web- og appversjon. Gå dit
                    for å dele din personlige link, eller se hvor mange av dine
                    venner som har registrert seg.
                </p>
                <p>
                    For hver venn du inviterer får du en ekstra måned gratis
                    premium, og hvis mer enn tre av vennene dine har registrert
                    seg innen 14.02.22 er du med i trekningen av 10 gavekort på
                    1000 NOK.
                </p>
            </>
        ),
    },
    '1.0.269': {
        en: (
            <>
                <p>
                    News! By inviting your friends, you now get a month free
                    access to premium for each friend who registers. In addition
                    to this you can win a gift card of 115 USD in your favourite
                    yarn shop if three or more of your friends has registered
                    within February 14th. 10 winners in total.
                </p>
                <Button
                    onClick={() => (window.location.href = '/invite-friends')}
                >
                    Invite friends
                </Button>
            </>
        ),
        no: (
            <>
                <p>
                    Nyhet! Ved å invitere venner får du nå en måned gratis
                    tilgang til all premiumfunksjonalitet for hver venn som
                    registrer seg. I tillegg til dette er du med i trekningen av
                    10 gavekort på 1 000 NOK i garnbutikken du selv velger om
                    tre eller flere av vennene dine har registrert seg innen 14.
                    februar.
                </p>
                <Button
                    onClick={() => (window.location.href = '/invite-friends')}
                >
                    Invitere venner
                </Button>
            </>
        ),
    },
    '1.0.261': {
        en: <p>Bugfix for calculation of yarn length and grams usage</p>,
        no: <p>Bugfix for beregning av garnlengde og grambruk</p>,
    },
    '1.0.260': {
        en: (
            <ul>
                <li>
                    You can now see yearly knitting stats by clicking the «yarn
                    used» button on the homepage
                </li>
                <li>
                    Pattern highlights are now cleared when the pattern is
                    changed in the project
                </li>
                <li>
                    Bugfix for issues with patterns that{'’'}s been missing
                    content
                </li>
            </ul>
        ),
        no: (
            <ul>
                <li>
                    Du kan nå se din strikkestatistikk ved å klikke på knappen
                    for «Brukt garn» på hjemskjermen.
                </li>
                <li>
                    Markering i oppskriften blir nå fjernet dersom oppskriften i
                    prosjektet byttes ut.
                </li>
                <li>
                    Bugfix for de som har hatt problemer med at noe av innholdet
                    i PDFen mangler
                </li>
            </ul>
        ),
    },
    '1.0.244': {
        en: (
            <>
                <p>
                    Updates will now happen automatically in the background
                    whenever you go to a new page in the app, so you don
                    {"'"}t need to press anything to get the latets version.
                    When new features or major changes is released we{"'"}ll
                    still send a notification on what{"'"}s new.
                </p>
                <p>
                    (If you would like to double check that you have the newest
                    version we have added a manual update option under {'"'}app
                    version{'"'} on your profile page.)
                </p>
            </>
        ),
        no: (
            <>
                <p>
                    Oppdateringer vil nå skje automatisk i bakgrunnen når du går
                    til en ny side, så du slipper varsler om nye oppdateringer.
                    Ved større endringer vil vi fortsatt gi beskjed om nye
                    funksjoner og liknende
                </p>
                <p>
                    (Om du vil dobbeltsjekke at du har nyeste versjon kan du
                    trykke på oppdateringssymbolet under
                    {'"'}app-versjon{'"'} på din profilsde. )
                </p>
            </>
        ),
    },
    '1.0.243': {
        en: <p>auto-updated app to new version</p>,
        no: <p>auto-opdaterring af appen til ny versjion</p>,
    },
    '1.0.210': {
        en: (
            <p>
                optimized loading of pattern highlights to prevent issues on
                some patterns
            </p>
        ),
        no: (
            <p>
                optimalisert lasting av oppskriftene markeringer for å forhindre
                problemer på enkelte oppskrifter
            </p>
        ),
    },
    '1.0.208': {
        en: (
            <ul>
                <li>the pattern highlighter can now be used on iPad</li>
                <li>
                    Some users has experienced that markings didn’t get saved.
                    This should be fixed with this update
                </li>
            </ul>
        ),
        no: (
            <ul>
                <li>markering i oppskriftene virker nå også på Ipad.</li>
                <li>
                    noen brukere har opplevd at markeringer ikke ble lagret.
                    Dette skal være fikset i denne oppdateringen.
                </li>
            </ul>
        ),
    },
    '1.0.203': {
        en: (
            <>
                <p>
                    We are so happy to release an update we know many have been
                    asking for, applicable for all users.
                </p>
                <ul>
                    <li>
                        You can finally highlight in your patterns! You can
                        choose between 6 different colors selected based on your
                        requests and input.
                    </li>
                    <li>
                        Updated sorting of yarn stash: In addition to beeing
                        sorted alphabetical, we have added alphabetical sorting
                        based on type as well.
                    </li>
                    <li>
                        {' '}
                        Pattern categories is updated to be sorted alpabetically{' '}
                    </li>
                    <li> Photos optimized to increase response. </li>
                </ul>
                <p>
                    Tip of the day: When you have selected the highlighter you
                    can still navigate in the pattern by using two fingers. You
                    zoom in/ out as normal. If you want to use several colors
                    you can simply change color by a click while highlighting.
                </p>
            </>
        ),
        no: (
            <>
                <p>
                    Vi er så glade for å slippe en oppdatering som vil gjelde
                    alle brukere, og har vært etterspurt av mange.
                </p>
                <ul>
                    <li>
                        Du kan endelig markere i oppskriftene dine! Velg mellom
                        6 ulike farger som er valgt ut fra deres ønsker og
                        tilbakemeldinger.
                    </li>
                    <li>
                        Oppdatert sortering garnlager: Garn blir sortert etter
                        navn, deretter alfabetisk etter type.
                    </li>
                    <li>
                        Oppskriftskategorier blir nå også sortert alfabetisk
                    </li>
                    <li>
                        Optimalisert bildestørrelser for å forbedre hastighet.
                    </li>
                </ul>
                <p>
                    Dagens tips: Når du har valgt markeringsverktøyet i
                    oppskriften kan du navigere ved å bruke to fingre. Zoome inn
                    og ut gjør du som vanlig. Hvis du vil bruke flere farger
                    endrer du enkelt underveis med ett enkelt klikk.
                </p>
            </>
        ),
    },
    '1.0.170': {
        en: (
            <p>
                Fixed an few issues with the calendar, number of completed
                projects and the pattern list
            </p>
        ),
        no: (
            <p>
                Rettet noen problemer med kalenderen, antall fullførte
                prosjekter og oppskrifterlisten
            </p>
        ),
    },
    '1.0.163': {
        en: (
            <>
                <ul>
                    <li>
                        Added share functionality to make it easier to invite
                        your friends
                    </li>
                    <li>
                        Android users can now download PDFs directly to knit
                        {'&'}note
                    </li>
                    <li>
                        And we have done som techincal optimization to make
                        things run even smoother :)
                    </li>
                </ul>
                <p>
                    Test out the new sharing here:{' '}
                    <span
                        onClick={() =>
                            (window.location.href = '/welcome-invited')
                        }
                    >
                        Invite friends
                    </span>
                </p>
            </>
        ),
        no: (
            <>
                <ul>
                    <li>
                        Oppdatert delefunksjonaliteten så det er enda enklere å
                        invitere med venner
                    </li>
                    <li>
                        Androidbrukere kan nå legge PDF rett i knit{'&'}note når
                        de laster ned
                    </li>
                    <li>
                        Så har vi optimalisert litt tekniske ting for å å få
                        ting til å gå enda bedre i bakkant :)
                    </li>
                </ul>
                <p>
                    Sjekk ut den nye delefunksjonaliteten her:{' '}
                    <span
                        onClick={() =>
                            (window.location.href = '/welcome-invited')
                        }
                    >
                        Invite friends
                    </span>
                </p>
            </>
        ),
    },
    '1.0.151': {
        en: (
            <>
                <p>Android users can now upload from from google drive.</p>
                <p>
                    <strong>Tip of the day:</strong> have you seen the row
                    counter that has been added in the projects?
                </p>
            </>
        ),
        no: (
            <>
                <p>androidbrukere kan nå laste opp fra google disk.</p>
                <p>
                    <strong>Dagens tips:</strong> har du fått med deg
                    omgangstelleren i prosjektene?
                </p>
            </>
        ),
    },
    '1.0.149': {
        en: (
            <>
                <ul>
                    <li>You can now upload photos in the yarn stash!</li>
                    <li>
                        New Premium feature: row counter available in the
                        projects. You can also view it while reading the
                        pattern.
                    </li>
                </ul>
                <p>
                    Tip of the day: If you add tags you can search for it across
                    all categories. What about adding
                    {'"'}christmas{'"'} to your christmas knitting to make it
                    easy to find back next year?
                </p>
            </>
        ),
        no: (
            <>
                <ul>
                    <li>Du kan nå laste opp bilder i garnlageret.</li>
                    <li>
                        Ny premiumfunksjon: omgangstelle er nå tilgjenglig i
                        prosjektet og mens du leser oppskriften.
                    </li>
                </ul>
                <p>
                    Dagens tips: hvis du legger på tags kan du søke på kryss og
                    tvers av alle kategorier. Kanskje noe å gjøre når du
                    strikker julestrikk så det er enklere å finne tilbake neste
                    år?
                </p>
            </>
        ),
    },
    '1.0.17': {
        en: <p>Bugfix for style on authentication inputs.</p>,
        no: <p>Feilretting for stil på autentiseringsinnganger</p>,
    },
    '1.0.135': {
        en: (
            <>
                <ul>
                    <li>You can now change pattern in an existing project.</li>
                    <li>
                        You will see a thubmail of the pattern in your pattern
                        list
                    </li>
                    <li>Fix deployed for PDF scrolling while zoomed in.</li>
                    <li>
                        And you can finally upload PDFs from Google Drive as
                        well on iphone!
                    </li>
                </ul>
                <p>
                    <strong>Tip of the day:</strong> did you know that you can
                    search for brand, type, color or gauge in the yarn stash?
                </p>
            </>
        ),

        no: (
            <>
                <ul>
                    <li>Du kan nå endre oppskrift i prosjektet.</li>
                    <li>
                        Du får nå se et lite bilde/ ikon av oppskriften i
                        oppskriftslisten
                    </li>
                    <li>
                        Det er kommet en fix for navigering i PDF når du er
                        zoomet inn.
                    </li>
                    <li>
                        Du får endelig også lastet opp filer fra google drive på
                        iphone!
                    </li>
                </ul>
                <p>
                    <strong>Dagens tips:</strong> visste du at du kan søke etter
                    både merke, type, farge og strikkefasthet i garnlageret?
                </p>
            </>
        ),
    },

    '1.0.131': {
        en: (
            <>
                <p>
                    Bugfix for uploading more than 2 project photos as premium
                </p>
            </>
        ),

        no: (
            <>
                <p>
                    Bugfix for opplasting av mer enn 2 prosjektbilder som
                    premium
                </p>
            </>
        ),
    },
    '1.0.130': {
        en: (
            <>
                <p>You can now choose between different subscriptions</p>
                <p>
                    Bugfix for iPhones where the Update Notification was showing
                    to often
                </p>
                <Button
                    onClick={() => (window.location.href = '/subscriptions')}
                >
                    Click to select subscription
                </Button>
            </>
        ),
        no: (
            <>
                <p>Du kan nå velge mellom ulike typer abonnement</p>
                <p>Bugfix for oppdateringsvarsel enkelte opplevde på Iphone</p>
                <Button
                    onClick={() => (window.location.href = '/subscriptions')}
                >
                    Klikk for å velge abonnement
                </Button>
            </>
        ),
    },
    '1.0.99': {
        en: <b>Payment integration is here!</b>,

        no: <b>Betalingsintegrasjon er her!</b>,
    },
    '1.0.98': {
        en: (
            <>
                <ul>
                    <li>
                        Fix deployed to ensure correct handling in the yarn
                        stash if you increase amount in a project
                    </li>
                </ul>
                <p>
                    <b>Tip of the day:</b> if 10 of your friends signs up to
                    knit{'&'}note in October you will get one year free premium
                    subscription, and you can win a gift card for 575 USD in
                    your favourite yarn shop.
                </p>
                <Button
                    onClick={() => (window.location.href = '/welcome-invited')}
                >
                    Invite friends
                </Button>
            </>
        ),
        no: (
            <>
                <ul>
                    <li>
                        Vi har nå lagt til en fix for å sikre riktig oppdatering
                        av garnlageret om du øker mengde i et prosjekt.
                    </li>
                </ul>
                <p>
                    <b>Dagens tips:</b> Hvis 10 av dine venner registrer seg i
                    oktober får du 12 måneders gratis premiumtilgang, i tillegg
                    til at du kan vinne gavekort på 5 000kr i garnbutikken du
                    liker best.
                </p>
                <Button
                    onClick={() => (window.location.href = '/welcome-invited')}
                >
                    Inviter venner
                </Button>
            </>
        ),
    },
    '1.0.96': {
        en: (
            <>
                <ul>
                    <li>Now the patterns are sorted in alphabetical order</li>
                    <li>Small design adjustments to some elements</li>
                </ul>
                <p>
                    Also remember to invite your friends! The launch giveaway
                    lasts until the end of October, so invite your friends your
                    today and you can win win some amazing prizes. Check out and
                    invite here:
                </p>
                <Button
                    onClick={() => (window.location.href = '/welcome-invited')}
                >
                    Inviter venner
                </Button>
            </>
        ),
        no: (
            <>
                <ul>
                    <li>Nå er oppskriftene sortert i alfabetisk rekkefølge</li>
                    <li>Det er gjort små justeringer på noen designelement</li>
                </ul>
                <p>
                    Husk også å invitere vennene dine! Lanseringsgiveawayen
                    varer ut oktober, så inviter vennene dine i dag og du kan
                    vinne vinne noen fantastiske premier. Sjekk ut og inviter
                    her:
                </p>
                <Button
                    onClick={() => (window.location.href = '/welcome-invited')}
                >
                    Invite friends
                </Button>
            </>
        ),
    },
    '1.0.94': {
        en: (
            <p>
                You can now add yarn directly from your yarn stash to the
                projects! If you use less yarn then planned you can simply
                change the amount of grams and the yarn stash will be updated.
            </p>
        ),
        no: (
            <p>
                Nå kan du endelig legge til garn fra garnlageret i prosjektene
                dine! Bruker du mindre garn enn planlagt kan du enkelt endre
                antall gram og resten kan føres tilbake til lageret
            </p>
        ),
    },
    '1.0.88': {
        en: (
            <>
                <ul>
                    <li>
                        In this upgrade we have updated the look of the knitting
                        planner to be the same as active- and completed
                        projects.
                    </li>
                    <li>
                        We have also added a welcome guide, which you can see as
                        well. It’s availble from the menu in the top corner of
                        the home page.
                    </li>
                </ul>
                <p>
                    <b>Tip of the day</b>: did you know that you can invite your
                    friends and be part of a big giveaway? Click on «invite
                    friend» under the top corner menu at home page to view
                    prizes and how to do it.
                </p>
                <Button
                    onClick={() => (window.location.href = '/welcome-invited')}
                >
                    Invite friends
                </Button>
            </>
        ),
        no: (
            <>
                <ul>
                    <li>
                        Vi hr oppdatert strikkeplaner så listen ser lik ut som
                        aktive- og fullførte prosjekter. Nå kan du f.eks legge
                        til bilde av hva du vil strikke eller kanskje garnet som
                        skal brukes?
                    </li>
                    <li>
                        Vi har lagt til envelkomstguide, som også du kan se. Den
                        er tilgjengelig fra menyen øverst på hjem-siden.
                    </li>
                </ul>
                <p>
                    <strong>Dagens tips</strong>: Del gleden med flere og vinn
                    premier. Klikk på «inviter venner» i menyen øverst på
                    hjemsiden for å se premiene og hvordan du inviterer gjennom
                    din link.
                </p>
                <Button
                    onClick={() => (window.location.href = '/welcome-invited')}
                >
                    Inviter venner
                </Button>
            </>
        ),
    },
    '1.0.77': {
        en: (
            <p>
                We have upgraded the pattern preview module. This fixes a
                preview issue for some users.
            </p>
        ),
        no: (
            <p>
                Vi har oppgradert visningsmodulen av oppskrifter. Det fikser en
                utfordring med visning av oppskrifter for enkelte brukere.
            </p>
        ),
    },
    '1.0.76': {
        en: (
            <p>
                You can now select your preferred languge on the Profile page,
                and we will use this for all communication with you.
            </p>
        ),
        no: (
            <p>
                Du kan nå velge ønsket språk på profilsiden, så bruker vi dette
                for all kommunikasjon med deg.
            </p>
        ),
    },
    '1.0.70': {
        en: (
            <>
                <ul>
                    <li>
                        From now on you will get a message with what is new when
                        the app is updated :)
                    </li>
                    <li>
                        For those who are technical: you can now see the app
                        version under the profile page.
                    </li>
                </ul>
                <p>
                    Today&apos;s tip: Did you know that you can change the
                    language by going to the menu at the top of the home page?
                </p>
            </>
        ),
        no: (
            <>
                <ul>
                    <li>
                        Fra nå av vil du få en melding med det som er nytt når
                        appen oppdateres :)
                    </li>
                    <li>
                        For de som er tekniske: dere kan nå se app-versjon under
                        profilsiden.
                    </li>
                </ul>
                <p>
                    Dagens tips: Visste du at du kan endre språk ved å gå inn på
                    menyen oppe på hjem-siden?
                </p>
            </>
        ),
    },
};

export default VersionUpgradeMessages;
