import localforage from 'localforage';

const languageKey = 'language';

/**
 * Setter and getter for laungage stored in IndexedDB through localforage
 */
export const LocalForageLanguage = {
    /**
     * Set language through localforage
     * @param language languageShape
     */
    set: (language: LanguageCode): void => {
        localforage.setItem(languageKey, language);
    },
    /**
     * Get language from localforage. Returns a promise which contains the language, if set
     * @returns Promise<languageShape | null>
     */
    get: (): Promise<LanguageCode | null> => {
        return localforage.getItem<LanguageCode>(languageKey);
    },
};
