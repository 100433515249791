import localforage from 'localforage';
import FetchWithKeepAlive from '_services/Api/_utils/FetchWithKeepAlive';
import { ProjectEndpoints } from '_constants/projectConstants';
import { ProjectUpdateDataShape } from '_types/Project';
import ProjectUpdateHelper from 'Hooks/Project/ProjectUpdateHelper';

export const projectDataKey = 'project_data';

export type LocalForageProjectDataShape = {
    [projectId: number]: ProjectUpdateDataShape;
} | null;

const LocalForageProjectData = {
    /**
     * Add or update Project data in IndexDB through localforage
     */
    addOrUpdate: (projectData: ProjectUpdateDataShape): void => {
        LocalForageProjectData.getList().then(
            (project: LocalForageProjectDataShape) => {
                if (!project) project = {};
                const projectId = projectData?.id;

                if (projectId) {
                    project[projectId] = {
                        ...(project[projectId] ?? {}),
                        ...projectData,
                    };

                    localforage.setItem(projectDataKey, project);
                }
            },
        );
    },
    getById: async (id: number): Promise<ProjectUpdateDataShape | null> => {
        const projectList = await LocalForageProjectData.getList();

        return (projectList && projectList[id]) ?? null;
    },

    /**
     * Get List of project data present in IndexDB
     */
    getList: (): Promise<LocalForageProjectDataShape> => {
        return localforage.getItem<LocalForageProjectDataShape>(projectDataKey);
    },

    /**
     * Delete Project data from IndexDB by id through localforage
     */
    delete: (projectId: number): void => {
        LocalForageProjectData.getList().then(
            (projects: LocalForageProjectDataShape) => {
                if (projects && projects[projectId]) {
                    delete projects[projectId];

                    localforage.setItem(projectDataKey, projects);
                }
            },
        );
    },

    /**
     * clear all after Sync with server
     */
    saveAndClearAll: (): void => {
        LocalForageProjectData.getList().then(
            async (projects: LocalForageProjectDataShape) => {
                if (projects) {
                    const url = ProjectEndpoints.update;
                    const projectIds = Object.keys(projects);

                    projectIds.forEach(async (projectId) => {
                        const project = projects[projectId] ?? null;
                        const id = Number(projectId);
                        if (!project) {
                            LocalForageProjectData.delete(id);
                            return;
                        }

                        FetchWithKeepAlive({
                            path: url,
                            method: 'POST',
                            body: JSON.stringify(project),
                        })
                            .then((res) => res.json())
                            .then((result) => {
                                LocalForageProjectData.delete(id);
                                /**
                                 * Updating the state of project in Redux
                                 */
                                ProjectUpdateHelper.onSuccess(result, project);
                            })
                            .catch((err) => {
                                console.error(
                                    'Error when saving and clearing in Product update in LocalForageProductData',
                                    err,
                                );
                            });
                    });
                }
            },
        );
    },
};

export default LocalForageProjectData;
