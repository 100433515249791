import { createApi } from '@reduxjs/toolkit/query/react';
import { callWithRequestLimiter as baseQuery } from '_services/Api/Api';
import { VideoTutorialVideoShape } from '_types/VideoTutorial';
import {
    VideoTutorialVideoCacheTag,
    VideoTutorialVideoCacheTagType,
} from '_services/Api/VideoTutorial/Video/VideoTutorialVideoApiCache';

export const VideoTutorialVideoApi = createApi({
    reducerPath: 'video-tutorial/video/api',
    tagTypes: ['VideoTutorialVideo'],
    keepUnusedDataFor: 60,
    baseQuery,
    endpoints: (builder) => ({
        getVideoTutorialVideoList: builder.query<
            VideoTutorialVideoShape[],
            string
        >({
            query: (lang) => ({ url: 'video-tutorial/videos?lang=' + lang }),
            providesTags: (res) => {
                let idTags: VideoTutorialVideoCacheTagType[] = [];
                if (res?.length) {
                    idTags = res.map((data) => ({
                        type: 'VideoTutorialVideo',
                        id: data.id,
                    }));
                }
                return [...idTags, VideoTutorialVideoCacheTag];
            },
        }),
    }),
});

export const { useGetVideoTutorialVideoListQuery } = VideoTutorialVideoApi;
