import localforage from 'localforage';

export const LocalForage = {
    getString: (key: string): Promise<string | null> =>
        localforage.getItem<string>(key),

    getNumber: (key: string): Promise<number | null> =>
        localforage.getItem<number>(key),

    get: async (key: string): Promise<string | number | null> => {
        try {
            const value = (await localforage.getItem(key)) as string | number;
            return value;
        } catch (error) {
            console.error(
                'error when getting data with key [' +
                    key +
                    '] from localforage:',
                error,
            );
        }
        return null;
    },

    set: (key: string, data: string | number): void => {
        localforage.setItem(key, data).catch((error) =>
            console.error(
                'error when getting data with key [' +
                    key +
                    '] from localforage',
                {
                    data: data,
                    error: error,
                },
            ),
        );
    },
};
